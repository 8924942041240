/**
 * This should be automated with script
 */

/**
 * PL
 */
import metaPL from './pl/meta.json';
import homepagePL from './pl/homepage.json';
// products
import emplobotPL from './pl/emplobot.json';
import intrabotPL from './pl/intrabot.json';
import custombotPL from './pl/custombot.json';
// solutions
import salesPL from './pl/sales.json';
import supportPL from './pl/support.json';
import recruitmentPL from './pl/recruitment.json';
import communicationPL from './pl/communication.json';
// technologies
import platformPL from './pl/platform.json';
import interfacePL from './pl/interface.json';
import aiPL from './pl/ai.json';
import automationPL from './pl/automation.json';
// about
import aboutPL from './pl/about.json';
import whyPL from './pl/why-emplocity.json';
import teamPL from './pl/team.json';
import jobsPL from './pl/jobs.json';
// investors
import investorsPL from './pl/investors.json';
import contactPL from './pl/contact.json';
// other
import otherPL from './pl/other.json';
// components
import integrationsPL from './pl/integrations.json';
import otherBotsPL from './pl/other-bots.json';
import topbarPL from './pl/topbar.json';
import contactSectionPL from './pl/contact-section.json';
import csrPolicyPL from './pl/csr-policy.json';
import hirebotPL from './pl/hirebot.json';
import wzaPL from './pl/wza.json';
import raportsPL from './pl/raports.json';

/**
 * EN
 */
import metaEN from './en/meta.json';
import homepageEN from './en/homepage.json';
// products
import emplobotEN from './en/emplobot.json';
import intrabotEN from './en/intrabot.json';
import custombotEN from './en/custombot.json';
// solutions
import salesEN from './en/sales.json';
import supportEN from './en/support.json';
import recruitmentEN from './en/recruitment.json';
import communicationEN from './en/communication.json';
// technologies
import platformEN from './en/platform.json';
import interfaceEN from './en/interface.json';
import aiEN from './en/ai.json';
import automationEN from './en/automation.json';
// about
import aboutEN from './en/about.json';
import whyEN from './en/why-emplocity.json';
import teamEN from './en/team.json';
import jobsEN from './en/jobs.json';
// investors
import investorsEN from './en/investors.json';
import contactEN from './en/contact.json';
// other
import otherEN from './en/other.json';
// components
import integrationsEN from './en/integrations.json';
import otherBotsEN from './en/other-bots.json';
import topbarEN from './en/topbar.json';
import contactSectionEN from './en/contact-section.json';
import csrPolicyEN from './en/csr-policy.json';
import hirebotEN from './en/hirebot.json';
import wzaEN from './en/wza.json';
import raportsEN from './en/raports.json';

function bundle(dict) {
  return Object.values(dict).reduce((acc, curr) => ({ ...acc, ...curr }), {});
}

export default {
  pl: bundle({
    metaPL,
    homepagePL,
    emplobotPL,
    intrabotPL,
    custombotPL,
    salesPL,
    supportPL,
    recruitmentPL,
    communicationPL,
    platformPL,
    interfacePL,
    aiPL,
    automationPL,
    aboutPL,
    whyPL,
    teamPL,
    investorsPL,
    otherPL,
    integrationsPL,
    contactPL,
    jobsPL,
    otherBotsPL,
    topbarPL,
    contactSectionPL,
    csrPolicyPL,
    hirebotPL,
    wzaPL,
    raportsPL,
  }),
  en: bundle({
    metaEN,
    homepageEN,
    emplobotEN,
    custombotEN,
    intrabotEN,
    salesEN,
    supportEN,
    recruitmentEN,
    communicationEN,
    platformEN,
    interfaceEN,
    aiEN,
    automationEN,
    aboutEN,
    whyEN,
    teamEN,
    investorsEN,
    otherEN,
    integrationsEN,
    contactEN,
    jobsEN,
    otherBotsEN,
    topbarEN,
    contactSectionEN,
    csrPolicyEN,
    hirebotEN,
    wzaEN,
    raportsEN,
  }),
};

import React, { createContext, memo, useContext, useMemo } from 'react';

export const RenderTypeContext = createContext();

const RenderTypeProvider = ({ children, renderType }) => {
  const value = useMemo(() => ({ renderType }), [renderType]);

  return <RenderTypeContext.Provider value={value}>{children}</RenderTypeContext.Provider>;
};

export const useRenderTypeContext = () => useContext(RenderTypeContext);

export default memo(RenderTypeProvider);

exports.components = {
  "component---src-components-blog-js": () => import("./../../../src/components/blog.js" /* webpackChunkName: "component---src-components-blog-js" */),
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-components-business-blog-js": () => import("./../../../src/components/business-blog.js" /* webpackChunkName: "component---src-components-business-blog-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-js": () => import("./../../../src/pages/about-us/about.js" /* webpackChunkName: "component---src-pages-about-us-about-js" */),
  "component---src-pages-about-us-contact-js": () => import("./../../../src/pages/about-us/contact.js" /* webpackChunkName: "component---src-pages-about-us-contact-js" */),
  "component---src-pages-about-us-jobs-js": () => import("./../../../src/pages/about-us/jobs.js" /* webpackChunkName: "component---src-pages-about-us-jobs-js" */),
  "component---src-pages-about-us-team-js": () => import("./../../../src/pages/about-us/team.js" /* webpackChunkName: "component---src-pages-about-us-team-js" */),
  "component---src-pages-about-us-why-emplocity-js": () => import("./../../../src/pages/about-us/why-emplocity.js" /* webpackChunkName: "component---src-pages-about-us-why-emplocity-js" */),
  "component---src-pages-csr-policy-js": () => import("./../../../src/pages/csr-policy.js" /* webpackChunkName: "component---src-pages-csr-policy-js" */),
  "component---src-pages-for-investors-investors-js": () => import("./../../../src/pages/forInvestors/investors.js" /* webpackChunkName: "component---src-pages-for-investors-investors-js" */),
  "component---src-pages-for-investors-raports-js": () => import("./../../../src/pages/forInvestors/raports.js" /* webpackChunkName: "component---src-pages-for-investors-raports-js" */),
  "component---src-pages-for-investors-stock-js": () => import("./../../../src/pages/forInvestors/stock.js" /* webpackChunkName: "component---src-pages-for-investors-stock-js" */),
  "component---src-pages-for-investors-wza-js": () => import("./../../../src/pages/forInvestors/wza.js" /* webpackChunkName: "component---src-pages-for-investors-wza-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-hirebot-js": () => import("./../../../src/pages/hirebot.js" /* webpackChunkName: "component---src-pages-hirebot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-products-custombot-js": () => import("./../../../src/pages/products/custombot.js" /* webpackChunkName: "component---src-pages-products-custombot-js" */),
  "component---src-pages-products-emplobot-js": () => import("./../../../src/pages/products/emplobot.js" /* webpackChunkName: "component---src-pages-products-emplobot-js" */),
  "component---src-pages-products-intrabot-js": () => import("./../../../src/pages/products/intrabot.js" /* webpackChunkName: "component---src-pages-products-intrabot-js" */),
  "component---src-pages-solutions-communication-js": () => import("./../../../src/pages/solutions/communication.js" /* webpackChunkName: "component---src-pages-solutions-communication-js" */),
  "component---src-pages-solutions-other-bots-js": () => import("./../../../src/pages/solutions/other-bots.js" /* webpackChunkName: "component---src-pages-solutions-other-bots-js" */),
  "component---src-pages-solutions-recruitment-js": () => import("./../../../src/pages/solutions/recruitment.js" /* webpackChunkName: "component---src-pages-solutions-recruitment-js" */),
  "component---src-pages-solutions-sales-js": () => import("./../../../src/pages/solutions/sales.js" /* webpackChunkName: "component---src-pages-solutions-sales-js" */),
  "component---src-pages-solutions-support-js": () => import("./../../../src/pages/solutions/support.js" /* webpackChunkName: "component---src-pages-solutions-support-js" */),
  "component---src-pages-technology-artificial-inteligence-js": () => import("./../../../src/pages/technology/artificial-inteligence.js" /* webpackChunkName: "component---src-pages-technology-artificial-inteligence-js" */),
  "component---src-pages-technology-automation-js": () => import("./../../../src/pages/technology/automation.js" /* webpackChunkName: "component---src-pages-technology-automation-js" */),
  "component---src-pages-technology-conversation-interface-js": () => import("./../../../src/pages/technology/conversation-interface.js" /* webpackChunkName: "component---src-pages-technology-conversation-interface-js" */),
  "component---src-pages-technology-platform-js": () => import("./../../../src/pages/technology/platform.js" /* webpackChunkName: "component---src-pages-technology-platform-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}


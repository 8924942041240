import React, { createContext, memo, useCallback, useMemo } from 'react';
import bundles from 'src/intl';

export const TranslationContext = createContext({});

const TranslationProvider = ({ children, lang, content }) => {
  const trans = Object.keys(bundles).includes(lang) ? bundles[lang] : bundles.pl;
  const t = useCallback((key) => trans[key] || key, [trans]);
  const externalContent = useMemo(
    () => (content ? { ...trans, ...content } : null),
    [content, trans]
  );

  return (
    <TranslationContext.Provider value={externalContent || trans}>
      {typeof children === 'function' ? children({ t }) : children}
    </TranslationContext.Provider>
  );
};

export default memo(TranslationProvider);

require('lazysizes');

const React = require('react');
const Helmet = require('react-helmet').Helmet;
const { Script } = require('gatsby');
const { default: LangProvider } = require('shared/LangProvider');
const { default: RenderTypeProvider } = require('shared/RenderTypeProvider');
const { default: TranslationProvider } = require('shared/Trans');
const { Meta } = require('react-head');

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location);

  window.scrollTo(...(currentPosition || [0, 0]));

  return false;
};

exports.wrapPageElement = ({ element, props }) => {
  const { pageContext } = props || {};
  const { lang, langs, originalPath, langVariants, pageName } = pageContext || {};

  return (
    <>
      <Helmet>
        <Meta name="facebook-domain-verification" content="n4u8a7xts6569go0p8yhhbdr3odpf3" />
      </Helmet>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-E76LBDV08W" id="gtag" />
      <Script id="analytics">
        {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-E76LBDV08W');`}
      </Script>

      <RenderTypeProvider renderType="prod">
        <LangProvider
          originalPath={originalPath}
          lang={lang}
          langs={langs}
          variants={langVariants}
          pageName={pageName}
        >
          <TranslationProvider lang={lang}>{element}</TranslationProvider>
        </LangProvider>
      </RenderTypeProvider>
    </>
  );
};

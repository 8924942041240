import React, { memo, useContext, createContext, useMemo } from 'react';

export const LangContext = createContext({ lang: 'pl', originalPath: '/' });

const LangProvider = ({
  lang,
  originalPath,
  langs = ['pl'],
  variants = {},
  children,
  pageName,
}) => {
  const value = useMemo(
    () => ({ lang, originalPath, langs, variants, pageName }),
    [lang, originalPath, langs, variants, pageName]
  );

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};

export const useLangContext = () => useContext(LangContext);

export default memo(LangProvider);

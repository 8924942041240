import { useCallback, useContext } from 'react';
import { TranslationContext } from './TranslationProvider';

export default function useTrans() {
  const translation = useContext(TranslationContext);

  const t = useCallback((key, substitute) => translation[key] || substitute || null, [translation]);

  return { t };
}
